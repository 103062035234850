import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import config from 'config';

import apiRequest from './ApiRequest';
import graphQLRequest from './GraphQLRequest';
import cacheRequest from './cacheRequest';

class ApiManager {
  constructor() {
    this.apiHost = config.apiProxyHost;
    this.graphqlHost = config.graphql.url;
  }

  static getRequestError(err) {
    if (err.response) {
      return err.response.data;
    }

    if (err.request) {
      return err.request;
    }

    return err.message;
  }

  static catchError = (errorResponse, { reject, actions, dispatch }) => {
    const error = errorResponse.response
      ? errorResponse.response.errors
      : errorResponse;

    if (actions.onError) {
      dispatch(ApiManager.actionBuilder(actions.onError)(error));
    }
    reject(error);
  };

  static actionBuilder(action) {
    if (typeof action === 'string') {
      return (data, additionalData) => ({
        type: action,
        data,
        additionalData,
      });
    }

    return action;
  }

  request({ dispatch, actions, requests, onSuccessData, useCache = true }) {
    const finalRequests = isArray(requests) ? requests : [requests];

    if (isEmpty(finalRequests)) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      if (actions.onInit) {
        dispatch(ApiManager.actionBuilder(actions.onInit)());
      }

      const requestsToProcess = finalRequests.map((el) => {
        if (el.type === 'graphql') {
          return cacheRequest(graphQLRequest, useCache, {
            url: this.graphqlHost,
            id: el.persisted ? el.query : undefined,
            query: el.persisted ? undefined : el.query,
            version: config.graphql.version,
            variables: el.variables,
          });
        }

        return cacheRequest(apiRequest, useCache, {
          method: el.method,
          url: this.apiHost + el.url,
          query: el.query,
        });
      });

      Promise.all(requestsToProcess)
        .then((res) => {
          if (actions.onSuccess) {
            const data = res.map((el) => el.data);

            dispatch(
              ApiManager.actionBuilder(actions.onSuccess)(data, onSuccessData)
            );
          }

          resolve(res);
        })
        .catch((err) => {
          ApiManager.catchError(err, { reject, actions, dispatch });
        });
    });
  }
}

export default new ApiManager();
