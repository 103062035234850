import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  priceListItems: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'PRICE_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'PRICE_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'PRICE_LIST/LOAD_DATA_ERROR',
};

const getPrice = (element) => {
  if (element.desc) {
    return element.desc;
  }

  if (element.min === element.max) {
    return element.min;
  }

  return {
    min: element.min,
    max: element.max,
  };
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        priceListItems: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          title: el.title,
          items: el.items.map((el2) => ({
            title: el2.entity.title,
            price: getPrice(el2.entity),
          })),
        })),
      };
    default:
      return state;
  }
};

export const loadData = (investment) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      query getPriceList($id: String) {
        nodeQuery(filter: {conditions: [
          {field: "type", value: ["building"]},
          {field: "status", value: ["1"]}
          {field: "field_building_investment.entity.nid", value: [$id]}
        ]}) {
          entities {
            ... on NodeBuilding {
              id: nid,
              title,
              items: fieldBuildingPricelist {
                entity {
                  ... on ParagraphPricelist {
                    title: fieldPricelistName,
                    min: fieldPricelistMin,
                    max: fieldPricelistMax,
                    desc: fieldPricelistDesc  
                  }
                }
              } 
            }
          }
        }
      }
    `,
    variables: { id: investment },
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
