import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'MEDIA_ABOUT_US_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'MEDIA_ABOUT_US_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'MEDIA_ABOUT_US_LIST/LOAD_DATA_ERROR',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        items: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          ...el,
          timestamp: GraphQLTransforms.timestamp(el.timestamp),
          body: GraphQLTransforms.value(el.body),
          thumbnail: GraphQLTransforms.imageStyle(el.thumbnail),
          fullImage: GraphQLTransforms.imageStyle(el.fullImage),
          url: GraphQLTransforms.value(el.url[0]),
          urlTitle: GraphQLTransforms.value(el.urlTitle[0]),
        })),
      };
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      {
        nodeQuery(limit: 100, filter: {conditions: [
          {field: "type", value: ["press_release"]},
          {field: "status", value: ["1"]}
        ]}) {
          entities {
            ... on NodePressRelease {
              id: nid,
              timestamp: entityCreated,
              title,
              body: fieldPressReleaseDescription {
                value
              },
              thumbnail: fieldPressReleaseLogo {
                derivative(style: LARGE) {
                  url
                }
              },
              fullImage: fieldPressReleaseScreen {
                derivative(style: ORIGINAL) {
                  url
                }
              },
              url: fieldPressReleaseLinks {
                value: uri
              },
              urlTitle: fieldPressReleaseLinks {
                value: title
              }
            }
          }
        }
      }
    `,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
