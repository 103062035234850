function trackYouleadFavourite(favouriteItems) {
   
  const ylData = window.ylData = window.ylData || [];

  const products = favouriteItems.map((favouriteItem) => ({
    productId: favouriteItem,
  }));

  ylData.push({ cart: { products } });

  return ylData;
}

export default trackYouleadFavourite;
