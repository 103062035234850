import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'PROMOTIONS_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'PROMOTIONS_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'PROMOTIONS_LIST/LOAD_DATA_ERROR',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        items: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          ...el,
          startDate: el.startDate.value,
          endDate: el.endDate.endValue,
          description: el.description.value,
          investment: GraphQLTransforms.value(el.investment),
          image: GraphQLTransforms.imageStyle(el.image),
          imageActive: GraphQLTransforms.imageStyle(el.imageActive),
        })),
      };
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      {
        nodeQuery(filter: {conditions: [
            {field: "type", value: ["special_offer"]},
            {field: "status", value: ["1"]}
        ]}, sort: [{
          field: "created"
          direction: DESC
        }]) {
          entities {
            ... on NodeSpecialOffer {
              id: nid,
              header: title,
              description: body {
                value
              },
              startDate: fieldSpecialOfferDates {
                value,
              },
              endDate: fieldSpecialOfferDates {
                endValue,
              },
              image: fieldSpecialOfferImage {
                derivative(style: NEWSTEASER) {
                  url,
                }
              },    
              imageActive: fieldSpecialOfferImage {
                derivative(style: PROMOTION) {
                  url,
                }
              },
              investment: fieldSpecialOfferInvestment {
                value: entity {
                  id: nid,
                  title,
                }
              },
              promotionId: fieldSpecialOfferPromoId,
            }
          }
        }
      }
    `,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
