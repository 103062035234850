import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
  slides: null,
};

export const actionTypes = {
  LOAD_DATA_INIT: 'BLOG/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'BLOG/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'BLOG/LOAD_DATA_ERROR',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0];
      return {
        ...state,
        items: GraphQLTransforms.getNode(data, 'blogs').map((el) => ({
          ...el,
          content: GraphQLTransforms.value(el.content),
          timestamp: el.timestamp * 1000,
          srcImage: GraphQLTransforms.imageStyle(el.srcImage),
        })),
        slides: GraphQLTransforms.getNode(data, 'slides').map((el) => ({
          ...el,
          imageUrl: GraphQLTransforms.imageStyle(el.imageUrl),
        })),
      };
    }
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      {
        blogs: nodeQuery(
        limit: 9999,
          filter: {
            conditions: [
              {field: "type", value: ["blog"]},
              {field: "status", value: ["1"]}
            ]
          }, 
          sort: [
            {
              field: "created"
              direction: DESC
            }
          ]
        ) {
          entities {
            ... on NodeBlog {
              id: nid,
              slug: fieldBlogSlug,
              title,
              content: fieldBlogContent {
                value
              },
              timestamp: created,
              srcImage: fieldBlogPostPhoto {
                derivative(style: BLOGSMALL) {
                  url,
                }
              },
            }
          }
        },
        slides: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["slide"]},
              {field: "status", value: ["1"]},
              {field: "field_slide_type", value: ["blog"]},
            ]
          },
        ) {
          entities {
            ... on NodeSlide {
              imageUrl: fieldSlideImage {
                derivative(style: MAINPAGESLIDER) {
                  url,
                }
              },
              title: fieldSlideTitle,
              description: fieldSlideDescription
            }
          }
        }
      }
    `,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
