import ApiManager from 'utils/ApiManager';
import Cookies from 'js-cookie';

export const initialState = {
  showMessage: false,
};

export const actionTypes = {
  RESET_STATE: 'COLLABORATION/RESET_STATE',
  SEND_DATA_INIT: 'COLLABORATION/SEND_DATA_INIT',
  SEND_DATA_SUCCESS: 'COLLABORATION/SEND_DATA_SUCCESS',
  SEND_DATA_ERROR: 'COLLABORATION/SEND_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_DATA_SUCCESS: {
      const newState = { ...initialState };

      newState.showMessage = true;

      return newState;
    }
    case actionTypes.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const resetState = () => ({
  type: actionTypes.RESET_STATE,
});

export const sendData =
  (data, { resetForm }) =>
  async (dispatch) => {
    // Function fillForm() belongs to YouLead library. Documentation available at: https://youlead.gitlab.io/#tag/ServerRequests/operation/fillForm.
    const ylid = Cookies.get('ylid') || '';
    const sessionId = Cookies.get('ylssid') || '';
    const utm = Cookies.get('ylutm') || '';

    const actions = {
      onInit: actionTypes.SEND_DATA_INIT,
      onSuccess: actionTypes.SEND_DATA_SUCCESS,
      onError: actionTypes.SEND_DATA_ERROR,
    };

    const newData = data;
    newData.formType = 'collaboration';

    const ylRequestQuery = {
      ylid: ylid.replace('browserId=', ''),
      sessionId: sessionId.replace('sessionId=', ''),
      pageUrl: 'https://start.com.pl/wspolpraca',
      referrer: '',
      utm,
      formId: 'contactUsForm',
      formData: data,
    };

    const requests = {
      method: 'POST',
      url: 'api/mailer',
      query: newData,
    };

    const ylTrackRequests = {
      method: 'POST',
      url: 'api/youleadFormTrack',
      query: ylRequestQuery,
    };

    try {
      await ApiManager.request({
        dispatch,
        actions,
        requests: ylTrackRequests,
        useCache: false,
      });

      resetForm();

      return ApiManager.request({
        dispatch,
        actions,
        requests,
        useCache: false,
      });
    } catch (error) {
      console.error('Monitoring data has not been sent. Error: ', error);
    }

    return null;
  };
