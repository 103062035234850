import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'CONSTRUCTION_JOURNAL_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'CONSTRUCTION_JOURNAL_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'CONSTRUCTION_JOURNAL_LIST/LOAD_DATA_ERROR',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        items: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          ...el,
          timestamp: el.timestamp * 1000,
          body: GraphQLTransforms.value(el.body),
          images: el.images.map(GraphQLTransforms.imageStyle),
          thumbnails: el.thumbnails.map(GraphQLTransforms.imageStyle),
        })),
      };
    default:
      return state;
  }
};

export const loadData = (id) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `query getJournal($id: String) {
      nodeQuery(filter: {conditions: [
        {field: "type", value: ["construction_journal"]},
        {field: "status", value: ["1"]},
        {field: "field_construction_investment.entity.nid", value: [$id]},
      ]}) {
        entities {
          ... on NodeConstructionJournal {
            id: nid,
            title,
            timestamp: created,
            images: fieldConstructionGallery {
              derivative(style: ORIGINAL) {
                url,
              }
            },
            thumbnails: fieldConstructionGallery {
              derivative(style: CONSTRUCTIONJOURNALGALLERY) {
                url,
              }
            }
            body {
              value
            },
          }
        }
      }
    }`,
    variables: { id },
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
