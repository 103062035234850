import ApiManager from 'utils/ApiManager';

export const initialState = {
  svgData: null,
  loadedId: null,
};

export const actionTypes = {
  LOAD_DATA_API_INIT: 'INVESTMENT_LOCAL_SEARCH/LOAD_DATA_API_INIT',
  LOAD_DATA_API_SUCCESS: 'INVESTMENT_LOCAL_SEARCH/LOAD_DATA_API_SUCCESS',
  LOAD_DATA_API_ERROR: 'INVESTMENT_LOCAL_SEARCH/LOAD_DATA_API_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_API_SUCCESS: {
      const newState = { ...initialState };

      newState.svgData = action.data[0];
      newState.loadedId = action.additionalData.loadedId;

      return newState;
    }
    default:
      return state;
  }
};

export const loadData = (id) => (dispatch, getState) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_API_INIT,
    onSuccess: actionTypes.LOAD_DATA_API_SUCCESS,
    onError: actionTypes.LOAD_DATA_API_ERROR,
  };

  const store = getState().InvestmentLocalSearchPage;

  if (store.loadedId !== id) {
    return ApiManager.request({
      dispatch,
      actions,
      requests: [
        {
          method: 'GET',
          url: `api/svgConverter/floor/${id}?attachWindRose`,
        },
      ],
      onSuccessData: { loadedId: id },
    });
  }

  return null;
};
