import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'CUSTOMER_OPINION_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'CUSTOMER_OPINION_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'CUSTOMER_OPINION_LIST/LOAD_DATA_ERROR',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        items: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          ...el,
          timestamp: GraphQLTransforms.timestamp(el.timestamp),
          body: GraphQLTransforms.value(el.body),
          summary:
            GraphQLTransforms.value(el.summary) ||
            GraphQLTransforms.value(el.body),
          thumbnail: GraphQLTransforms.imageStyle(el.thumbnail),
          fullImage: GraphQLTransforms.imageStyle(el.fullImage),
        })),
      };
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      {
        nodeQuery(
          limit: 1000, 
          filter: {
            conditions: [
              {field: "type", value: ["customer_feedback"]}, {field: "status", value: ["1"]}
            ]
          }, 
          sort: [
            {field: "created", direction: DESC}
          ]
        ) {
          entities {
            ... on NodeCustomerFeedback {
              id: nid
              timestamp: entityCreated
              body: fieldCustomerFeedbackText {
                value
              },
              summary: fieldCustomerFeedbackText {
                value: summary
              }
              thumbnail: fieldCustomerFeedbackScan {
                derivative(style: LARGE) {
                  url
                }
              }
              fullImage: fieldCustomerFeedbackScan {
                derivative(style: ORIGINAL) {
                  url,
                }
              } 
            }
          }
        }
      }
    `,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
