import _includes from 'lodash/includes';

export const initialState = {
  sortSelected: 'local-asc',
};

export const actionTypes = {
  CHANGE_SORT_OPTION: 'INVESTMENT_TABLE_SEARCH/CHANGE_SORT_OPTION',
};

 
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SORT_OPTION: {
      const { sortSelected } = state;
      const selected = action.data;
      let data;
      if (_includes(sortSelected, `${selected.id}-asc`)) {
        data = `${selected.id}-desc`;
      } else {
        data = `${selected.id}-asc`;
      }
      return {
        ...state,
        sortSelected: data,
      };
    }
    default:
      return state;
  }
};

export const changeSortOption = (data) => ({
  type: actionTypes.CHANGE_SORT_OPTION,
  data,
});
